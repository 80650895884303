import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function Networking() {

    return (
      <>
        <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>Get Network  <span style={{ color: '#F7631B' }}>Assignment Help </span> from the Experts</h1>
              <p className="header_sub_font mb-4">Are you struggling to complete your computer network assignment? Do not worry; Quick Assignment is here with a team of experts who can help you to complete the assignments efficiently. Most of the students today dread the negative impact of failing in computer network assignments, which also affect their academic goals and career development.  </p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content"><p className="service_content">The professionals at Quick Assignments service understand that importance of scoring good marks in the assignments along with the pressure that the students have to go through while managing their studies and extra activities. Hence, the expert provide high-quality networking assignments exactly as per the course requirements. The experts specialized in topics like cloud, data centre, strategy, planning, WAN  to wireless technology, protocols, network architecture, security issues, trouble shooting, and whatnot. They are also proficient when it comes to concepts like TCP /IP, and network topologies, which are quite challenging students to understand and implement in their assignments. This is where they need to seek help from the experts and attain tailored guidance. The professional assignment helper provides in-depth explanations and assists the students in their practical assessments. In addition to this, taking guidance for assessments from experts can help to save valuable time and students can balance their course learnings, their part-time jobs, and extra activities. Taking help for networking-related assignments is not just about getting the assignments completed from experts but it is about helping students to enhance their understanding of the concepts, theories, and practical implications and be prepared for the final examinations. By making use of expert help, students can build a strong foundation in networking which will help them to attain higher grades in class and enhance their skills. It is time to make use of the opportunity and excel in studies while also preparing for future success in the field of networking. </p>
                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default Networking; 