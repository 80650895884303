import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function It() {

    return (
      <>
       <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>IT  <span style={{ color: '#F7631B' }}>Quick Assignment </span> help</h1>
              <p className="header_sub_font mb-4">IT assignments are considered to be complicated and result in calling for knowledge of various concepts, languages and technologies. From different coding challenges to the designing and implementing of database design, such tasks calls for high-tech proficiency along with an enhanced creativity and analytical skills and abilities. However, there are times when students may feel highly frustrated when coping with growing demands. So, we at Quick Assignment understand the raising complexity of various IT assignments and available actively to relive your burden </p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">Our IT experts have more than 10 years of experience in addressing various IT challenges. Quick Assignment IT services provided by our experts include Java, Python, IoT, Artificial intelligence, networking, web development, HTML, CSS, Data structures, C/C++ programming, databases like SQL, PHP and many more. 
                </p>
                <p className="service_content">Also, we focus on providing help to students at all the academic levels which mainly include students pursuing their bachelor’s, master’s, professional, vocational, diploma or dissertation level reporting degree. However, our team includes highly experienced PhD IT experts that benefits in providing all necessary insights to provide outstanding assignments. Irrespective of your need/assistance in understanding any concept or designing any database, IT team at Quick Assignment is always ready to help you. We actively help you in completing your IT assignments from very simple coding tasks to highly complex database projects while meeting different academic standards. Thus, we believe that studying or enrolling in IT field involves much more than completing your assignments timely. By effectively outsourcing your IT assignments you can easily free your time in focusing on all other most significant aspects related to academics or personal life. Our professionals at Quick Assignment strive to provide high-quality IT assignments that benefit in meeting all major course requirements with an enhanced understanding of subject in an efficient manner.   
                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default It; 