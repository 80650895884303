import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function EnglishLitreture() {

    return (
      <>
        <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>How to get help  <span style={{ color: '#F7631B' }}>with English Literature </span> Assignment</h1>
              <p className="header_sub_font mb-4">All English literature students who wish to score well in exams and expand their skills and knowledge while managing the course learnings and extracurricular activities should consider taking online assignment help. Quick assignment offers the students English literature assignment help so that they can attain higher grades in class while also focusing on their career development. </p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">Navigating the world of English literature is exciting as well as challenging for the students because of its themes, historical context, and different genres in which the students find themselves in challenging situations. If you are feeling the same pressure, then you must consult our English literature experts who can provide you with the desired solutions.  
                </p>
                <p className="service_content">English literature assignments include comprehension skills, and in-depth interpretation, like analyzing Shakespeare's plays, analyzing poetry, etc. The experts are skilled and trained to understand the intricate themes and characters of plays efficiently, because of which seeking professional assistance is important. Another benefit of taking expert help is gaining knowledge and expert details which not only help in the learning process but also in gaining higher grades in class which is important for future career goals. In addition to this, engaging with experienced professionals can help the students enhance their writing skills, sentence structuring techniques, and grammar. 
                </p>
                <p className="service_content">
                    Our experts ensure to provide the assignments way before the actual deadline and can also help in delivering urgent tasks efficiently without compromising on the quality. For students managing multiple tasks of different subjects can be challenging because of seeking help from our experts can make you feel more confident. Don’t let the challenges of English literature assignment stress you out, instead, focus on taking help so that you can enhance your understanding and writing skills to attain academic success and also achieve career goals. 
                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default EnglishLitreture; 