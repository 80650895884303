import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function Tourism() {

    return (
      <>
        <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>Tourism Assignment  <span style={{ color: '#F7631B' }}>Help to achieve academic </span> excellence</h1>
              <p className="header_sub_font mb-4">Tourism is the world’s fastest growing sector, which has encouraged many students to choose this option for their career development. However, during the course, the students face various challenges in completing their assignments. Seeking assignment help during the course can help the students to attain their academic goals and be prepared for their future endeavours. </p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">Tourism assignment focuses on destination planning, marketing traveller behaviour as well as their impact on the communities. To complete the tasks efficiently it is important for the students to understand the concepts. Assignment help service helps the students by providing clear real-life examples, which makes the concepts more reliable and understandable.
 
                </p>
                <p className="service_content">One important aspect of tourism education is that it requires proper research, data analysis, evaluation of the ongoing trends, and assessing the market condition while also understanding the policies. The experts at Quick Assignment know the tourism standards and policies, which they use efficiently to complete the assignments. The experts not only help to complete the tasks but also help the students in the learning process, which is important for their career development in the tourism industry. Assignments help focus on one session where the students can explain their task requirements while also clearing any doubts related to concepts or theories. 
                </p>
                <p className="service_content">
                Quick Assignment is known for delivering high quality and unique tourism assignments. What makes us different from other assignment help services is that we offer round-the-clock student support, we have a team of highly qualified and experienced writers, and we ensure timely delivery of the assignments, and that too at an affordable pricing structure. At Quick Assignment you can get everything, you need to attain higher grades in class that too without compromising your extracurricular activities.
                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default Tourism; 