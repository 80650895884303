import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function Businessmgnt() {

    return (
      <>
        <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>Enhance your academic  <span style={{ color: '#F7631B' }}>performance with Business </span> Management Assignment support </h1>
              <p className="header_sub_font mb-4">Advancements in technology have helped the students to gain maximum help for different subjects from online experts. The best way to enhance academic performance is to seek help from professionals in marketing, business management, finance, or any other subject.   </p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">Completing business management tasks can be challenging for students as it involves understanding organizational behaviour, strategic planning, managing resources, etc. This is where our specialized business management assignment help expert service comes in which is developed to support the students in attaining academic success.  
                </p>
                <p className="service_content">Business management is a vast topic, which includes leadership theories, operational management, and financial analysis. Every assignment requires the usage of comprehensive knowledge and skills along with the ability to implement them successfully in real-world scenarios. The team of experts at Quick Assignment offers customized guidance, which aligns with the needs of the student and the requirements of the course. One of the unique features of our service is our commitment to providing high-quality assignments to the students. Our experts are skilled, experienced, and trained to adhere to academic standards and deliver 100% plagiarism-free assignments to the student. Whether you need help with research papers, dissertations, thesis, reports, or project proposals, we have the expertise to deliver compelling content that will help you attain higher grades in class.  In addition, you do not have to worry about the strict assignment deadlines as our team is dedicated to completing and submitting the assignments on time, to avoid the last-minute pressure.  
                </p>
                <p className="service_content">
                All you need to do is get in touch with our experts, gain valuable insight, and enhance the learning experience related to business management assignments. Do not let academic pressure affect your performance, contact us for assignment help, boost your grades, and enhance your knowledge to be prepared for a successful career in business management.

                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default Businessmgnt; 