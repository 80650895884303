import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function Economics() {

    return (
      <>
       <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>Are you looking  <span style={{ color: '#F7631B' }}>for Economics </span> Assignment Help?
              </h1>
              <p className="header_sub_font mb-4">Quick Assignment help provides assignment help provides high-quality economic assignment help solutions to the students. The world out there has become quite competitive and challenging with increasing academic pressure, and stress to attain the goals, while also managing extracurricular activities. Let us help you to manage the academic challenges that you face when completing the economic assignments.</p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">You will surely require good expertise and knowledge to gain higher grades in economics, and this is where we barge in. Economic assignments include complex theories, data analysis, and real-world applications which can be quite complex to solve without assistance. Seeking effective assignment help is not only important to enhance academic grades but also to gain a deeper knowledge of the concepts & theories discussed in class. 
                </p>
                <p className="service_content">Economics includes topics related to microeconomics, macroeconomics, demand and supply, international trade, and more. Each of these areas has different complexities, which require the students to firstly understand the concepts and theories and then only then can implement it efficiently to complete their assignment as per the requirements or criteria. Assignment help at Quick Assignment aims to clarify theoretical concepts by dividing them into smaller topics for students to understand efficiently. One important aspect of economic assignment is that it requires extensive research and data analysis; it requires statistical techniques to interpret the data, which is somewhat complicated. Effective assignment help guide the students to understand the research process, and usage of credible resources while also gaining knowledge of how to analyze the data using the right model. The experts at Quick Assignment provide personalized support to the students and help to complete the assignments as per the criteria mentioned. So whether you need one tutoring session, access to resources, or help in completing the assignment  
                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default Economics; 