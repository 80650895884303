import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function HealthCare() {

    return (
      <>
        <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>Why choose us  <span style={{ color: '#F7631B' }}>for Healthcare </span> assignment help?</h1>
              <p className="header_sub_font mb-4">Students who have enrolled in medical-related academic programs have to prepare a range of medical assignments that require them to implement the right theories and concepts. These assignments demand extensive research as well as strong analytics skills. As students navigate through their healthcare courses, they encounter challenging assignments that require critical analysis along with the usage of practical knowledge.  
              </p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">Quick Assignment provides high-quality assignment help, which helps the students to gain academic success followed by the fulfilment of professional goals.  
                </p>
                <p className="service_content">Healthcare assignments can include various case studies, research papers, analysis of policies, and clinical assessments. Each of these tasks requires students to understand the medical concepts, terms, protocols, and standards of the healthcare system. Our healthcare assignment experts break down these concepts and make them accessible as well as relatable, while also providing students with detailed explanations to help them understand the principles. One of the important aspects of healthcare education is that it requires the implementation of an evidence-based approach for which the students have to focus on evaluating studies and implementing knowledge in real-world situations. This can be a challenging process for the students, which requires guidance from experts to not only complete the assignments on time but also to prepare them for their further clinical practice. Our assignment help service provides tutoring the students, providing structured essays, help in developing arguments while also ensuring that the assignment requirements are being considered. 
                </p>
                <p className="service_content" >Our experts recognize and understand that every student has different requirements because of which they aim to provide them with customized tutoring sessions, credible resources, and high-quality assignments, which helps to address their needs efficiently. Thus, by providing comprehensive assignment help services to the students, the experts at Quick Assignment foster a supportive learning environment for the students and help them to be prepared to become healthcare practitioners in the future. </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default HealthCare; 