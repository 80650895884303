import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function Accounting() {

    return (
      <>
      <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>How can Quick  <span style={{ color: '#F7631B' }}>Assignment help with </span> accounting assignments?              </h1>
              <p className="header_sub_font mb-4">Quick Assignment aims to understand all major complexities faced by the average student while doing his/her accounting assignments. Working with Quick Assignment would benefit you in providing support for completing your accounting assignments within a short period without compromising the quality of work being delivered. Quick Assignment believes we have a great accountant who would benefit from assisting you with your accounting assignments.</p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">The online assistants of Quick Assignment have more than 10 years of experience in the accounting field. Also, the accounting experts of Quick Assignment emphasize accessing all necessary materials required to complete your accounting assignment. For instance, the accounting expert at Quick Assignment may gather all the desired accounting data from relevant and authorised accounting data sources. In addition, Quick Assignment has more than 10,000 assignments that have been effectively solved over the last five years. However, we at Quick Assignment would benefit you by completing your accounting assignment as per your requirements promptly. 
                </p>
                <p className="service_content">Thus, we focus on providing customised accounting solutions which aim to address the issue while ensuring that no instruction could be left overlooked. For most of the accounting assignments, it is necessary to present all the gathered data in Excel with the necessary computations in the Word document. However, we at Quick Assignment emphasize providing both essential documents with clear calculations. Thus, our team aims to provide accurate answers while ensuring that you get high distinction grades in all the accounting assignments. Just you need to tell us ‘I need help with my accounting assignment’ or ‘I am facing trouble in completing my accounting assignment’ and we are here for you to complete your assignment within the desired time period.  
                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default Accounting; 