import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function Marketing() {

    return (
      <>
      <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>Attain Academic <span style={{ color: '#F7631B' }}>Success with Marketing </span> Assignments Help</h1>
              <p className="header_sub_font mb-4">In today’s competitive and challenging academic environment, attaining high grades has become crucial for students to thrive in the business world. The complexities of marketing concepts, along with strict deadlines can create chaos for the students, leading to stress.  
              </p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">This is where Quick Assignment experts help the students by providing them with necessary assistance in completing marketing assignments while offering students the required support to attain their academic goals.  
                </p>
                <p className="service_content">Marketing assignments require much research and a deep understanding of various theories, models, and real-world applications. Our experts at Quick Assignment connect with the students, understand their requirements and deliver high-quality marketing assignments, tailored to the coursework. One of the main benefits of our assignment help service is the ability to deliver well-researched, high quality, and plagiarism free assignments, which demonstrates the key skills and knowledge of our marketing experts. The experts at Quick Assignment stay up to date with the latest concepts, marketing trends, and theories to ensure that the projects delivered are impactful. Therefore, if you need any kind of assistance with marketing cases, developing marketing plans, or any other marketing-based reports, our specialists are always here to guide you. Most importantly the services provided by Quick Assignment focus on learning for which the experts collaborate with the students to provide them with valuable insights related to marketing strategies and practices which eventually helps them to understand the concepts efficiently. Timeliness is another important aspect considered by our experts, as they understand the importance of task deadlines while also ensuring that they deliver high quality work.   
                </p>
                <p className="service_content">
                Whether you need expert assistance to enhance your grades or any other marketing assignment help, the specialists at Quick Assignment are here to pave the way for your successful career in marketing.   
                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default Marketing; 