import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function Nursing() {

    return (
      <>
        <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}>Do you find  <span style={{ color: '#F7631B' }}>Nursing Assignments </span>Challenging? Get expert help now</h1>
              <p className="header_sub_font mb-4">Nursing plays an important role in medical science as it aims to provide support and care for patients. Nurses have big responsibilities like administering medication, managing medical equipment, and taking care of the patient, which requires extensive training and knowledge. If you are a student pursuing nursing science, then you need to check your subject knowledge to complete the assignments efficiently.</p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">Completing nursing assignments efficiently requires proper theoretical knowledge as well as piratical knowledge. As nursing students navigate their coursework, they do face certain challenges and this is where the experts can help.  
                </p>
                <p className="service_content">Nursing assignments include complex topics like anatomy, pharmacology, patient care, policies, and ethics. The challenge for the student is not only to complete the assignments successfully but also to manage their academic learning and other day-to-day activities. This is why asking for online assignment assistance can help to make their academic journey less stressful. The experts at Quick Assignment offer various benefits, like providing access to credible and reliable sources for the assignment, helping to clarify the concepts, and helping the students understand the practical implications while also giving examples of real-life nursing cases and scenarios. This not only helps to complete the assignment on time but also reinforces learning for the future. In addition to this, seeking help for nursing assignments helps to manage stress as the students get the chance to focus on other important aspects of education, like preparing for examinations and training for nursing. Whether the assignment is related to developing a care plan for the patient, conducting research, writing reports or essays, students can ask for any help. Thus, by making use of expert online guidance, students can enhance their knowledge related to nursing concepts and succeed in their nursing careers in the future.
  
                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default Nursing; 