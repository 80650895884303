import React from "react";
import about_banner from '../../assets/img/newHome/about_banner.png'

function Finance() {

    return (
      <>
        <div className="home_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" style={{ marginTop: 75 }}>
              <h1 className="header_font" style={{ textAlign: 'left', fontSize: 55 }}> Finance <span style={{ color: '#F7631B' }}>  Quick </span>Assignment help </h1>
              <p className="header_sub_font mb-4">Are you tired of searching for the most efficient finance Quick Assignment solution to be completed promptly? Relax! You are at the right place. We at Quick Assignment have helped thousands of students in satisfactorily completing their finance assignments. We understand that assignments sometimes become a nightmare for the students who have to cater to different responsibilities. So, it may require enhanced supervision in the financial domain to be completed on time. Stop wasting time and get great finance Quick Assignment help quickly. 
              </p>
              <div style={{ display: 'flex' }}>
                <a className="get_started" href="/contact">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62622 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="white" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={about_banner} alt="banner_img" className="banner_image" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
            <div className="container">
              <div className="row ">
                <p className="service_content">We believe that our writers at Quick Assignment are effectively engrained with experience of many years and an enhanced knowledge in the financial domain. Finance professionals are accustomed to the university guidelines. Also, all the finance writers are effectively trained so that they can provide efficient assignment help to all our students in an efficient manner. With more than 1000+ Quick Assignment experts online, we focus on delivering an enhanced quality of assignments set before the deadline.  
                </p>
                <p className="service_content">Also, you can easily get in touch with our finance Quick Assignment expert in case of any modification in the assignment delivered. So, get ready to effectively improve your finance grades with the finance Quick Assignment expert. Thus, we believe that academic assignments aim to determine student evaluation. You could easily hire the finance Quick Assignment service to get great marks with excellent writing. Reach out to a finance Quick Assignment expert and get ready to get good grades. We would love to help you in achieving high distinction grades.  
                </p>
              </div>
            </div>
      </div>
      </>
    )
}

export default Finance; 